<template>
  <Layout :tituloPagina="'Inicio VPN'">
    <div class="row" v-show="!$store.state.todo.susargus">
      <div class="col-lg-12">
        <div class="card bg-warning text-white">
          <div class="card-body text-center">
            <p class="card-text">
              Su suscripción ha caducado, debe de pagar su mensualidad para seguir disfrutando de ArgusBlack, 
              visite la sección <a href="/sistema/cuenta/" style="color: red;">CUENTA</a> para renovarla en este momento.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xxl-3 col-lg-3 col-sm-6">
        <WCredenciales/>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main.vue"
import WCredenciales from './WCredenciales.vue'

export default {
  name: 'Inicio',
  components: {
    Layout,
    WCredenciales
  }
}
</script>

<style scoped>

</style>
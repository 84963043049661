<template>
  <div class="card">
    <h5 class="card-header text-truncate w-100">
      <span class="badge bg-primary rounded-pill bx-pull-right font-size-12">
        Total
      </span>
      Credenciales
    </h5>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12 text-center">
          <router-link :to="{ name: 'listadoCredencialesVpn' }">
            <div class="avatar mx-auto">
              <div class="avatar-title rounded-circle bg-info bg-gradient font-size-20" :title="cantidad_total">
                {{ cantidad_total <= 9999 ? cantidad_total : '999+' }}
              </div>
            </div>
            <div class="text-info text-center text-truncate w-100">
              Credenciales registradas 
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CredencialSrv from '@/services/vpn/CredencialSrv.js'

export default {
  name: 'WCredenciales',
  data() {
    return {
      cantidad_total: 0,
      intervals: {
        actualizacion: null
      }
    }
  },
  created() {
    var self = this

    this.actualizar()
    this.intervals.actualizacion = setInterval(function() {
      self.actualizar()
    }, 60000)
  },
  beforeUnmount() {
    clearInterval(this.intervals.actualizacion)
  },
  methods: {
    actualizar() {
      var self = this

      CredencialSrv.cantidadTotal().then(response => {
        self.cantidad_total = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  }
}
</script>